<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import PickerDate from '@/components/DateCustoms/PickerDate/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
import TableListComponent from '@/components/TableList/main.vue';

import { keyMaster, masterMethods, checkPermission, prizeMethods, employeeMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';
import { validateField } from '@/utils/validate';
import { handleDetailReturnPrize } from './index';
import { InputCheckBox } from '@/components/Input';

export default {
    page: {
        title: '賞品戻り管理一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Layout,
        Footer,
        Multiselect,
        PickerDate,
        DateNormal,
        InputCheckBox,
        TableListComponent
    },
    data() {
        return {
            campaign_pic: null,
            isLoading: false,
            checkShowFilter: false,
            listData: [],
            listDataMasterCampaignPic: [],
            listReturnStatus: [],

            form: {
                campaign_name: '',
                reason: '',
                return_date: '',
                return_date_y: '',
                return_date_m: '',
                return_date_d: '',
                storage_code: '',
                contact_information_number: '',
                shipping_company: '',
                province: '',
                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: '',
                event_prize: ''
            },
            has: {
                campaign_pic: '',
                prize: '',
                return_status: '',
                campaign: ''
            },
            configKeyword: {
                arrayTag: [],
                placeholder: '賞品タイトル、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo',
                isShowLable: true
            },
            configTable: {
                headers: [
                    {
                        label: '終了FLG'
                    },
                    {
                        label: 'No.'
                    },
                    {
                        label: '戻り日'
                    },
                    {
                        label: 'キャンペーン名'
                    },
                    {
                        label: '賞品タイトル'
                    },
                    {
                        label: '都道府県'
                    },
                    {
                        label: '返送運賃（倉庫着払い）'
                    },
                    {
                        label: '戻り対応ステータス',
                        required: true
                    },
                    {
                        label: '再確認日'
                    },
                    {
                        label: '運送会社'
                    },
                    {
                        label: '問合せ№'
                    },
                    {
                        label: '戻り理由',
                        required: true
                    },
                    {
                        label: 'パルディアより対応指示項目'
                    },
                    {
                        label: '日本物流開発様対応'
                    },
                    {
                        label: '追跡番号'
                    },
                    {
                        label: '保管棚番'
                    },
                    {
                        label: '備考'
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            }
        };
    },
    watch: {
        campaign_pic: {
            deep: true,
            handler: function (value) {
                this.has.campaign_pic = value?.id ?? '';
            }
        }
    },
    mounted() {
        this.getListReturnStatus();
        this.getListMasterCampaignPic();
        this.getList();
    },

    methods: {
        checkPermission,
        ...masterMethods,
        ...prizeMethods,
        ...employeeMethods,

        async getListMasterCampaignPic() {
            try {
                const dataRes = await this.listMaster('campaign/common-user');
                this.listDataMasterCampaignPic = dataRes['admin_and_instructor']; //1,3
            } catch (error) {
                console.log('error', error);
            }
        },
        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }

                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listPrizeReturn(query).then((data) => {
                handleDetailReturnPrize(data);
                this.listData = data.data;
                this.configPage.total = data._metadata.total;
                this.configPage.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            });
        },

        deletePrizeRe(data) {
            Swal.fire({
                title: '賞品戻りを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.value) {
                    this.deletePrizeReturn(data).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '賞品戻りが削除されました。');
                            this.getList();
                        }
                    });
                }
            });
        },
        search() {
            this.getList();
        },
        clear() {
            this.configKeyword = {
                arrayTag: [],
                placeholder: 'キャンペーン名、賞品タイトル、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo',
                isShowLable: true
            };
            this.form = {
                campaign_name: '',
                reason: '',
                return_date: '',
                return_date_y: '',
                return_date_m: '',
                return_date_d: '',
                storage_code: '',
                contact_information_number: '',
                shipping_company: '',
                province: '',
                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: '',
                prize: ''
            };
            this.has = {
                campaign_pic: '',
                prize: '',
                return_status: '',
                campaign: ''
            };
            this.campaign_pic = null;
            this.getList();
        },
        saveItem(item) {
            if (!checkPermission('return_prize.edit')) return;
            if (!item.reason || !item.return_status || !item.return_date) {
                return;
            }
            item = {
                ...item,
                return_status: {
                    id: item?.return_status?.id ?? null
                }
            };
            this.updatePrizeReturn(item).then((data) => {
                if (data.code !== 200) {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                }
            });
        },
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.arrayTag = [];
            } else {
                this.configKeyword.placeholder = '賞品タイトル、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo';
                this.configKeyword.arrayTag = [];
            }
        },

        async getListReturnStatus() {
            this.isLoading = true;

            try {
                let data = await this.listMaster(keyMaster['prize.corresponding_status']);
                this.listReturnStatus = data;
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },

        hasReturnStatus(param) {
            return param.return_status?.value === '確認中' || param.return_status?.value === '保管' ? false : true;
        },

        handleRemoveReturnStatus(obj) {
            let check = true;
            obj.reconfirmation_schedule = null;
            check = validateField(obj.configReturnStatus, check);
            if (!check) return;
            this.saveItem(obj);
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>

                                    <div v-if="checkShowFilter">
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン名<span class="text-danger">*</span></label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.campaign_name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">賞品タイトル</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.event_prize" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">戻り理由</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.reason" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">案件担当</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-campaign_pic-default`"
                                                    :value.sync="campaign_pic"
                                                    :options="listDataMasterCampaignPic"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'name',
                                                        error: false,
                                                        isRemove: false,
                                                        searchable: true
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">戻り日</label>
                                            <div class="col-sm-9">
                                                <DateNormal
                                                    :year.sync="form.return_date_y"
                                                    :month.sync="form.return_date_m"
                                                    :day.sync="form.return_date_d"
                                                    :full_date.sync="form.return_date"
                                                    :config="{
                                                        errorText: '',
                                                        valueSync: '',
                                                        error: false
                                                    }"
                                                >
                                                </DateNormal>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">保管棚番</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.storage_code" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">運送会社</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.shipping_company" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">都道府県</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.province" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">事務局終了日</label>
                                            <div class="col-sm-9">
                                                <DateNormal
                                                    :year.sync="form.secretariat_end_date_y"
                                                    :month.sync="form.secretariat_end_date_m"
                                                    :day.sync="form.secretariat_end_date_d"
                                                    :full_date.sync="form.secretariat_end_date"
                                                    :config="{
                                                        errorText: '',
                                                        valueSync: '',
                                                        error: false
                                                    }"
                                                >
                                                </DateNormal>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">問い合わせNo</label>
                                            <div class="col-sm-9">
                                                <input
                                                    id="validationCustom05"
                                                    v-model="form.contact_information_number"
                                                    type="text"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>

                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">賞品戻り検索結果 （{{ configPage.total }})</h3>
                    </div>
                    <div class="card-body">
                        <TableListComponent
                            :config="configTable"
                            :dataList="listData"
                            :isLoading="isLoading"
                            :configPage.sync="configPage"
                            :stickyAction="true"
                            @onChangePage="getList()"
                        >
                            <template #row="{ row, index }">
                                <tr>
                                    <td>
                                        <div>
                                            <InputCheckBox
                                                class="custom-switch"
                                                :model.sync="row.end_flag"
                                                :id="`__BVID__742` + index"
                                                :value="true"
                                                :label="``"
                                                @change="saveItem(row)"
                                            />
                                        </div>
                                    </td>
                                    <!-- No. -->
                                    <td>
                                        {{ index }}
                                    </td>
                                    <!-- 戻り日 * -->
                                    <td style="width: 123px">
                                        <div>
                                            <PickerDate
                                                style="min-width: 130px"
                                                :id="`return_date${index}`"
                                                :model.sync="row.return_date"
                                                :config.sync="row.configFieldReturnDate"
                                                @change="saveItem(row)"
                                            />
                                        </div>
                                        <div v-if="!row.return_date" class="mt-1">
                                            <span class="text-danger">{{
                                                $t('validateField.requiredSelect', {
                                                    field: '戻り日'
                                                })
                                            }}</span>
                                        </div>
                                    </td>
                                    <!-- キャンペーン名 -->
                                    <td>
                                        <div class="row-data-small" v-b-tooltip.hover :title="row.event_prize?.campaign?.name ?? ''">
                                            {{ row?.event_prize?.campaign?.name ?? '' }}
                                        </div>
                                    </td>
                                    <!-- 賞品タイトル -->
                                    <td>
                                        <div class="row-data-small" v-b-tooltip.hover :title="row.event_prize?.title ?? ''">
                                            {{ row?.event_prize?.title ?? '' }}
                                        </div>
                                    </td>
                                    <!-- 都道府県 -->
                                    <td>
                                        <input
                                            type="text"
                                            @change="saveItem(row)"
                                            v-model="row.province"
                                            style="width: 140px"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                        />
                                    </td>
                                    <!-- 返送運賃（倉庫着払い） -->
                                    <td>
                                        <input
                                            type="text"
                                            @change="saveItem(row)"
                                            v-model="row.shipping_return_fee"
                                            style="width: 140px"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                        />
                                    </td>
                                    <!-- 戻り対応ステータス -->
                                    <td>
                                        <Multiselect
                                            :id="`select-return_status-${index}`"
                                            :value.sync="row.return_status"
                                            :options="listReturnStatus"
                                            :config="row.configReturnStatus"
                                            @select="handleRemoveReturnStatus(row)"
                                            @remove="handleRemoveReturnStatus(row)"
                                        />
                                    </td>
                                    <!-- 再確認日 -->
                                    <td>
                                        <div>
                                            <PickerDate
                                                style="min-width: 130px"
                                                :id="`scheduled_arrived_at${index}`"
                                                :model.sync="row.reconfirmation_schedule"
                                                :config.sync="row.configFieldReconfirmation"
                                                :disabled="hasReturnStatus(row)"
                                                @change="saveItem(row)"
                                            />
                                        </div>
                                    </td>
                                    <!-- 運送会社 -->
                                    <td>
                                        <input
                                            type="text"
                                            v-model="row.shipping_company"
                                            style="width: 140px"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                            @change="saveItem(row)"
                                        />
                                    </td>
                                    <!-- 問合せ№ -->
                                    <td>
                                        <input
                                            type="text"
                                            @change="saveItem(row)"
                                            style="width: 140px"
                                            v-model="row.contact_information_number"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                        />
                                    </td>
                                    <!-- 戻り理由 * -->
                                    <td class="input-group-sm">
                                        <div>
                                            <input
                                                type="text"
                                                @change="saveItem(row)"
                                                style="min-width: 140px"
                                                v-model="row.reason"
                                                placeholder=""
                                                class="form-control-sm form-control"
                                                :class="{ 'is-invalid': !row.reason }"
                                            />
                                        </div>
                                        <div v-if="!row.reason" class="mt-1">
                                            <span class="text-danger">{{
                                                $t('validateField.required', {
                                                    field: '戻り理由'
                                                })
                                            }}</span>
                                        </div>
                                    </td>

                                    <!-- パルディアより対応指示項目 -->
                                    <td>
                                        <input
                                            type="text"
                                            @change="saveItem(row)"
                                            v-model="row.instruction"
                                            style="width: 140px"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                        />
                                    </td>
                                    <!-- 日本物流開発様対応	-->
                                    <td>
                                        <input
                                            type="text"
                                            @change="saveItem(row)"
                                            v-model="row.compatiable_with_jp_logistic_development"
                                            style="width: 140px"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                        />
                                    </td>
                                    <!-- 追跡番号 -->
                                    <td>
                                        <input
                                            type="text"
                                            @change="saveItem(row)"
                                            v-model="row.tracking_number"
                                            style="width: 140px"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                        />
                                    </td>
                                    <!-- 保管棚番 -->
                                    <td>
                                        <input
                                            type="text"
                                            @change="saveItem(row)"
                                            v-model="row.storage_code"
                                            style="width: 140px"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                        />
                                    </td>
                                    <!-- 備考 -->
                                    <td>
                                        <input
                                            type="text"
                                            @change="saveItem(row)"
                                            v-model="row.note"
                                            style="width: 140px"
                                            placeholder=""
                                            class="form-control-sm form-control"
                                        />
                                    </td>
                                    <td align="center">
                                        <button
                                            v-if="checkPermission('return_prize.delete')"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="deletePrizeRe(row)"
                                            style="border-radius: 50%"
                                        >
                                            <i class="fa fa-minus"></i>
                                        </button>
                                    </td>
                                </tr>
                            </template>
                        </TableListComponent>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
th,
td {
    white-space: nowrap;
}
th:last-child,
td:last-child {
    position: sticky;
    right: 0;
    background: #fff;
    z-index: 999;
}
.mx-input {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    min-width: 123px;
}
</style>
